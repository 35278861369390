@import "colors";

body {
  overflow: hidden;
}

.app-header.MuiAppBar-root {
  background-color: $uw-system-red;
}

p {
  font-weight: 400;
  font-size: 16px;
  a {
    color: $blue;
    &:visited {
      color: inherit;
    }
  }
}
/**
    Action Buttons
**/

.MuiButtonBase-root.MuiButton-root.Mui-disabled {
  color: $dark-gray;
}

.appNameLink {
  color: $white;
  text-decoration: none;
  &:focus {
    outline: 2px dotted $white;
  }
}

.primary-action-btn.MuiButton-root {
  color: $white;
  background-color: $blue;
  border: 2px solid transparent;
  &:hover {
    background-color: rgba(0, 62, 107, 0.2);
    border: 2px solid $blue;
    color: $blue;
  }
  &:focus {
    background-color: rgba(0, 62, 107, 0.2);
    color: $blue;
    outline: 2px dotted $blue;
  }
  &.Mui-disabled {
    background-color: $light-gray;
    outline: none;
    border: none;
  }
}

.secondary-action-btn.MuiButton-root {
  color: $blue;
  border: 2px solid $blue;
  background-color: $white;
  &.Mui-disabled {
    border: 2px solid $dark-gray;
  }
  &:focus {
    background-color: rgba(0, 62, 107, 0.2);
    outline: 2px dotted $blue;
  }
  &:hover {
    border: 2px solid $blue;
    background-color: rgba(0, 62, 107, 0.2);
  }
}

.MuiFormHelperText-root.formHelperText {
  color: $blue;
}
.MuiFormHelperText-root.formHelperText {
  font-size: 1rem;
}

.MuiButton-text.upload-btn {
  color: $blue;
  background-color: rgba(0, 62, 107, 0.2);
}

.MuiButton-text.upload-btn {
  &:hover {
    background-color: rgb(0 62 107 / 12%);
  }
  &:focus {
    outline: 2px dotted $blue;
  }
}

.createButton.MuiButtonBase-root {
  margin: 1em 0px;
  float: right;
}

.MuiIconButton-root.closeSnackbar:hover {
  background-color: rgba(255 255 255 / 22%);
}

.MuiIconButton-root.closeSnackbar:focus {
  background-color: rgba(255, 255, 255, 0.22);
  outline: 2px dotted #fff;
}

#client-snackbar {
  font-size: 16px;
  display: flex;
  .MuiSvgIcon-root {
    margin-right: 0.4em;
  }
}

.table-action-link {
  text-decoration: none;
  color: $blue;
  font-weight: 500;
  padding: 6px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  &:hover {
    background-color: rgba(0, 62, 107, 0.1);
  }
  &:focus {
    outline: 2px dotted $blue;
    background-color: rgba(0, 62, 107, 0.1);
  }
  .MuiSvgIcon-root {
    margin-right: 0.1em;
  }
}

.button-style-link.MuiButton-root {
  text-transform: capitalize;
  color: $blue;
  margin-bottom: 1.3em;
  text-decoration: underline;
  &:hover {
    background-color: rgba(0, 62, 107, 0.2);
  }
  &:focus {
    outline: 2px dotted $blue;
    text-decoration: none;
    color: $white;
    background-color: $blue;
  }
}

.MuiPaper-root .MuiButton-textPrimary:hover {
  background-color: rgba(0, 62, 107, 0.2);
}

.MuiPaper-root .MuiButton-textPrimary:focus {
  outline: 2px dotted #003e6b;
}

p {
  a:focus,
  a:active {
    outline: 2px dotted $blue;
    text-decoration: none;
    color: $blue;
  }
}

/**
    Global Overrides
**/

.org-selection.MuiListItem-root.Mui-selected:hover,
.org-selection.MuiListItem-button:hover,
.org-selection.MuiListItem-button:focus,
.MuiListItem-root.Mui-selected:hover,
.MuiListItem-root.Mui-selected:focus,
.MuiListItem-button:hover,
.MuiAutocomplete-option:hover,
.MuiAutocomplete-option:focus,
.MuiMenuItem-root:hover {
  background-color: rgba(0, 62, 107, 0.2) !important;
}

.org-selection.MuiListItem-button:focus,
.MuiListItem-root.Mui-selected:focus,
.MuiAutocomplete-option:focus,
.MuiMenuItem-root:focus {
  outline: 2px dotted $blue;
  background-color: rgba(0, 62, 107, 0.2) !important;
}

.MuiListItem-root.Mui-selected,
.org-selection.MuiListItem-root.Mui-selected,
.MuiAutocomplete-option[aria-selected="true"] {
  background-color: rgba(0, 62, 107, 0.2) !important;
  font-weight: 500;
}

.mainNavigationList {
  .MuiListItem-button {
    color: $blue;
    font-weight: 400;
    &:hover {
      background-color: rgba(0, 62, 107, 0.2);
    }
    &:focus {
      background-color: rgba(0, 62, 107, 0.2);
      outline: 2px dotted $blue;
    }
    .MuiSvgIcon-root {
      color: $blue;
    }
  }
  .MuiListItem-button.active {
    background-color: rgba(0, 62, 107, 0.2);
    border-right: 5px solid $blue;
    font-weight: 500;
    .MuiTypography-displayBlock {
      font-weight: 500;
    }
  }
}

.MuiListItem-button {
  color: $blue;
  &:focus {
    outline: 2px dotted $blue;
    background-color: rgba(0, 62, 107, 0.2);
  }
}

.MuiTouchRipple-root {
  color: rgba(0, 62, 107, 0.2);
}

.org-switcher-select {
  .MuiSelect-select:focus {
    background-color: rgba(0, 62, 107, 0.2);
    outline: 2px dotted $blue;
  }
}

.MuiIconButton-root:hover:not(.uw-dialog-title
    .MuiIconButton-root:hover, .profileButton:hover, .closeSnackbar:hover, .uw-menu-btn.MuiIconButton-root:hover) {
  background-color: rgba(0, 62, 107, 0.2) !important;
}

.MuiIconButton-root:focus:not(.uw-dialog-title
    .MuiIconButton-root:focus, .closeSnackbar:focus, .profileButton.MuiIconButton-root:focus, .uw-menu-btn.MuiIconButton-root:focus) {
  background-color: rgba(0, 62, 107, 0.2);
  outline: 2px dotted $blue;
}

.MuiTooltip-tooltip {
  font-size: 12px !important;
}

/**
    Profile
**/

.uw-userMenu {
  .MuiAvatar-root {
    background-color: $blue;
  }
  .MuiListItem-button {
    justify-content: center;
    &:hover,
    &:focus {
      background-color: rgba(0, 62, 107, 0.2);
    }
    &:focus {
      outline: 2px dotted $blue;
    }
  }
}

.MuiMenuItem-root {
  .uw-userName {
    font-weight: 500;
    padding-left: 8px;
  }
  .uw-betaTester {
    padding-left: 8px;
  }
}

.profileButton {
  .MuiAvatar-root {
    background-color: $uw-system-red;
    border: 1px solid $white;
  }
}

.profileButton.MuiIconButton-root {
  &:hover {
    background-color: rgba(250, 250, 250, 0.2);
  }
  &:focus {
    background-color: rgba(250, 250, 250, 0.2);
    outline: 2px dotted $white;
  }
}

.avatar-icon.MuiAvatar-root {
  background-color: $light-blue;
}

/**
    Breadcrumbs
**/

.uw-breadcrumbs.MuiTypography-colorTextSecondary {
  color: $dark-gray;
  margin-bottom: 0.5rem;
}

.uw-breadcrumbs .MuiLink-underlineHover {
  &:hover {
    text-decoration: underline;
    color: #333;
  }
  &:focus-visible {
    box-shadow: 0 0 0 1px $blue;
  }
}
/**
    Hamburger button
**/

.MuiPaper-root {
  .uw-menu-btn.MuiIconButton-root {
    margin-right: 12px;
    color: $white;
    padding: 13px 15px 9px;
    &:hover {
      background: rgba(250, 250, 250, 0.2);
      box-shadow: none;
    }
    &:focus {
      outline: 2px dotted $white;
      background: rgba(250, 250, 250, 0.2);
    }
  }
}

/**
    Details Card
**/

.featured-icon-card {
  text-align: center;
  display: flex;
  align-items: center;
  height: 100%;
  .MuiSvgIcon-root {
    font-size: 4em;
    color: $blue;
  }
}

.details-list {
  .MuiListItemText-primary {
    font-size: 16px;
    font-weight: 400;
    color: $blue;
  }
  .MuiListItemText-secondary {
    font-size: 18px;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.87);
  }
  a.MuiListItem-root {
    &:hover {
      background-color: rgba(0, 62, 107, 0.1);
    }
    &:focus {
      background-color: rgba(0, 62, 107, 0.1);
      outline: 2px dotted $blue;
      color: $blue;
    }
    &:visited {
      color: inherit;
    }
  }
  .MuiAvatar-root {
    background-color: $blue;
  }
  .success .MuiAvatar-root {
    background-color: $green;
  }
  .warning .MuiAvatar-root {
    background-color: $orange;
  }
  .info .MuiAvatar-root {
    background-color: $light-blue;
  }
  .error .MuiAvatar-root {
    background-color: $uw-system-red;
  }
}

/**
    Forms
**/

.uw-form {
  .MuiDialogActions-root {
    background-color: rgba(0, 62, 107, 0.2);
    box-shadow: 0 0 0 1px $blue;
  }
  .uw-form-input-field {
    margin-bottom: 1.5rem;
  }

  .MuiOutlinedInput-notchedOutline {
    border: 2px solid $blue;
    &:focus {
      outline: 2px dotted $blue;
    }
  }
  .MuiSelect-icon {
    color: $blue;
  }
  .MuiFormLabel-root,
  .MuiFormLabel-root.MuiFocused {
    color: $blue !important;
  }
  .MuiFormHelperText-root.Mui-error,
  .MuiFormLabel-asterisk.Mui-error,
  .MuiFormLabel-root.Mui-error {
    color: $uw-system-red;
  }
  .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline {
    border-color: $uw-system-red;
  }
}

.MuiFormControl-root.uw-staff-form {
  margin-top: 1em;
  .uw-radio {
    padding-top: 5px;
  }
}

[data-reach-skip-link] {
  color: $blue;
  font-weight: 500;
}

[data-reach-skip-link]:focus {
  z-index: 1300 !important;
  outline: 2px dotted $white;
}

.MuiCardContent-root,
.MuiPaper-root,
.uw-radio {
  .MuiButton-textPrimary,
  .MuiIconButton-root:not(.MuiPickersDay-daySelected, .Mui-disabled, .closeSnackbar, .uw-menu-btn),
  .MuiRadio-root.Mui-checked {
    color: $blue;
  }
  .MuiPickersDay-daySelected {
    background-color: $blue;
    &:focus {
      color: $blue;
      outline: 2px dotted $blue;
    }
    &:hover {
      color: $blue;
      border: 2px solid $blue;
    }
  }
}

.MuiPickersDay-daySelected .uw-radio {
  .MuiIconButton-label,
  .MuiFormLabel-root:focus,
  .MuiIconButton-label.Mui-checked,
  .MuiButtonBase-root.Mui-checked {
    color: $blue;
  }
  .MuiFormLabel-root:focus {
    outline: 2px dotted $blue;
  }
}

.uw-footer {
  padding: 12px;
  background-color: #333;
  color: $white;
}

[data-reach-skip-link] {
  color: $blue;
  font-weight: 500;
}

[data-reach-skip-link]:focus {
  z-index: 1300 !important;
}

/**
    Main Content
**/

.uw-pageHeadings {
  padding-bottom: 1em;
  padding-top: 2em;
}

.uw-pagePaper.MuiPaper-root {
  background-color: transparent;
  box-shadow: none;
}

/**
    Cards
**/

.root {
  display: flex;
  flex-direction: column; // allows for sticky footer
  height: 100vh;
  background-color: #7a99ac17;
}

.uw-card {
  .MuiCardActions-root {
    justify-content: center;
  }
  .fileUploadDownload {
    margin-top: 1em;
    .MuiLink-underlineHover {
      text-decoration: none;
    }
  }
}

.MuiCardActions-root.cardSingleAction {
  padding: 0;
  border-radius: 0;
  background-color: rgba(0, 62, 107, 0.2);
  a {
    width: 100%;
    padding: 16px;
  }
}
.uw-card-details,
.uw-card {
  height: 100%;
  display: flex;
  flex-direction: column;
  .MuiListItem-root.Mui-disabled {
    opacity: 1;
  }
  .MuiCardHeader-root,
  .MuiToolbar-regular {
    border-bottom: 2px solid rgb(204 204 204 / 28%);
    .MuiCardHeader-subheader {
      color: $dark-gray;
    }
  }
  .MuiCardActions-root {
    margin-top: auto;
  }
}

/**
    Table
**/

.uw-table-container {
  .MuiDataGrid-columnHeadersInner {
    background-color: rgba(0, 62, 107, 0.1);
    text-transform: uppercase;
    color: $blue;
    font-size: 16px;
    font-weight: 600;
    .MuiTableSortLabel-root:focus {
      outline: 2px dotted $blue;
      color: $blue;
    }
  }

  .MuiDataGrid-iconButtonContainer {
    .MuiIconButton-root {
      margin-left: 5px;
    }
  }

  .MuiDataGrid-columnSeparator--sideRight:hover {
    pointer-events: none;
  }

  .MuiDataGrid-columnHeaderTitleContainer:hover {
    background-color: #c6d1dc59;
  }
  .MuiDataGrid-columnHeaderTitleContainer:focus {
    outline: 2px dotted $blue;
    background-color: #c6d1dc59;
  }

  .MuiDataGrid-root {
    border: none;
  }
  .MuiDataGrid-row,
  .MuiTablePagination-toolbar,
  .MuiTablePagination-selectLabel,
  .MuiTablePagination-displayedRows {
    font-size: 16px;
    font-weight: 400;
    color: $blue;
    background-color: white;
  }
  .MuiDataGrid-row:nth-child(even) {
    background-color: rgb(0 62 107 / 2%);
  }
  .MuiDataGrid-row:nth-child(odd) {
    background-color: $white;
  }
  .negative {
    background-color: rgba(0, 62, 107, 0.2);
    padding: 1px 6px;
    border-radius: 15px;
  }
  .positive {
    background-color: rgb(155 212 155 / 32%);
    padding: 1px 6px;
    border-radius: 15px;
  }
  .status-icon {
    position: relative;
    top: 4px;
    font-size: 1.1rem;
  }

  .MuiDataGrid-toolbarContainer {
    background-color: #c6d1dc;
    padding-top: 2px;
    padding-bottom: 2px;
  }
  .MuiPaper-root .MuiButton-textPrimary {
    padding: 8px;
    margin-right: 4px;
    &:hover {
      background-color: $white;
    }
    &:focus {
      background-color: $white;
      outline: 2px dotted $blue;
    }
  }
  .MuiSelect-select:focus {
    outline: 2px dotted $blue;
    background-color: rgb(0 62 107 / 15%);
  }
  .MuiSelect-select:hover {
    color: #000;
  }
}

.MuiDataGrid-panel {
  .MuiInputBase-root.MuiInput-root:after {
    border-bottom: 2px solid $blue;
  }
  .MuiInput-input::placeholder {
    color: $blue;
    opacity: 0.8;
  }
  .MuiInputLabel-root {
    color: $blue;
    font-size: 16px;
  }
  .MuiInputLabel-root.Mui-focused {
    font-weight: 500;
    color: $blue;
  }
  .MuiSwitch-thumb {
    color: $blue;
  }
  .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track {
    background-color: #c6d1dc;
    opacity: 1;
  }
}
.MuiButtonBase-root-MuiMenuItem-root-MuiTablePagination-menuItem:hover {
  background-color: rgba(0, 62, 107, 0.2);
}

.uw-pagePaper.MuiPaper-root {
  background-color: transparent;
}

/**
    Dialog
**/

.uw-dialog {
  .uw-dialog-title {
    color: $white;
    background-color: $blue;
    line-height: 2.6;
    .MuiButtonBase-root {
      color: white;
      float: right;
      &:focus {
        outline: 2px dotted $white;
      }
      &:hover {
        background-color: rgba(250, 250, 250, 0.2);
      }
    }
  }
  .uw-dialog-content.MuiDialogContent-root {
    padding-top: 24px;
    .MuiDialogContentText-root {
      color: rgba(0, 0, 0, 0.87);
    }
  }
  .MuiDialogActions-root {
    padding: 24px;
  }
}

.screenreaderOnly {
  position: absolute;
  left: -999px;
  width: 1px;
  height: 1px;
  top: auto;

  &:focus {
    display: inline-block;
    height: auto;
    width: auto;
    position: static;
    margin: auto;
  }
}

.MuiChip-root.uw-chip {
  background-color: $blue;
  cursor: pointer;
}

/**
    Media Queries
**/
@media screen and (max-width: 599px) {
  .createButton.MuiButtonBase-root {
    float: left;
  }
}

@media screen and (max-width: 899px) {
  .uw-form {
    .MuiAutocomplete-inputRoot {
      margin-bottom: 1.5rem;
    }
  }
}


$primary-red-border-color: #3f0016;

/***
    UW System Primary Colors
***/

$uw-system-red: #990033;
$dark-red: #691C32;
$light-gray: #bbbbbb;
$dark-gray: #606060;

/***
    UW System Secondary Colors
***/

$purple: #5c2b59;
$teal: #006469;
$blue: #003e6b;
$green: #939408;
$orange: #f7921e;
$yellow: #f1c400;
$gold: #cdb97d;
$light-blue: #7a99ac;

/***
    Other Colors
***/

$white: #ffffff;
$primary-red-light: rgba(152, 0, 51, 0.1);

